import React from 'react';
import { Typography, Drawer, Button } from '@material-ui/core';
import ButtonComponent from '../button.component';
import './scss/checkout-sign-in-phone.scss';
import { ICheckoutShowedPageNames, ICheckoutSignInInputVcode, ICheckoutSignInInputVcodeProps, ICheckoutSignInInputVcodeState } from '../../interfaces';
import VerificationCode from './verification-code.component';
import { MdChevronLeft } from 'react-icons/md';
import { IS_VCODE } from '../../constants/base.constant';
import { signIn_param } from './checkout-sign-in-password-page';
import i18n from '../../i18n';
import apiManage from '../../request';
import { GOOGLE_ROBOT_SCORE } from '../../gconfig';
import Notification from '../../component/snackbar/snackbar';
import _ from 'lodash';
import ReCaptChaDialog from '../recaptcha-dialog/recaptcha-dialog';

import { cleanAccountCache } from '../../constants/tool-check-token';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { errorCatching } from '../../tool/error-catching';
import { Customer } from '../../models';
import { createHashHistory } from 'history';
import Store from '../../rudex/store';
// import { GoogleLogin } from 'react-google-login';
import { GoogleLoginFixed } from '../google-login/google-login';
import { FeatGoogleReCaptcha, TimeoutAtCodeDelay } from '../../page/sign-in/sign-in.page';
import { VCodeDialog } from '../v-code-dialog/v-code-dialog';

class SignInCodePage extends React.Component<Props, State> implements ICheckoutSignInInputVcode {

    public state: State = {
        // 文本框 - 验证码
        vcode: '',
        // 底部托盘的状态（调整此属性后，运行动画）
        bottomPanelVisible: false,
        // 谷歌人机校验获取token
        showGoogleReCaptcha: false,
        // 谷歌人机校验图像弹窗
        showReCaptChaDialog: false,
        // 是否通过人机校验
        isPassReCaptcha: false,
        // 验证码类型
        vcodeType: 'SMS',
        // 验证码弹窗
        showVCodeDialog: {
            show: false
        }
    }

    signInBtnAvailable = true;
    whatsappVcodeSendAvailable = true;
    // *********************
    // Life Cycle Function
    // *********************
    componentDidMount(): void {
        this.setState({
            vcodeType: LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.VERIFY_CODE_PENDING_SOURCE) === 'WHATSAPP' ? 'WHATSAPP' : 'SMS'
        })
    }
    // *********************
    // Default Function
    // *********************

    /**
     * #### 验证码登录 获取顾客头像和顾客信息,
     * 判断顾客信息是否完善, 不完善跳入信息补全页面
     */
    public async signIn() {
        const { phone, phonePrefix } = this.props;
        const params = {
            phone,
            phone_prefix: phonePrefix,
            code_value: this.state.vcode
        }
        await this.props.signIn(params);
    }

    /**
     * 通过reCaptchaToken解析分值
     */
    public async checkReCaptchaToken(reCaptchaToken) {
        try {
            if (reCaptchaToken) {
                const result = await apiManage.googleReCaptchaSiteverify({ token: reCaptchaToken })
                if (!_.get(result, 'error')) {
                    if (result.score <= GOOGLE_ROBOT_SCORE) {
                        this.setState({
                            showReCaptChaDialog: true
                        })
                    } else {
                        // 评分高，已验证不是机器人
                        this.setState({
                            isPassReCaptcha: true
                        })
                        // 发送验证码
                        this.props.sendPhoneGetVerificationCode(this.state.vcodeType === 'WHATSAPP');
                    }
                }
            }
        } catch (error) { }
    }

    /**
     * 处理ReCaptchaV2回调
     */
    public handelReCaptchaCallBack(result: 'Success' | 'Expire' | 'Error') {
        // 操作成功
        let message = 'general_text_successful_operation';
        if (result === 'Success') {
            // 通过成功
            this.setState({
                isPassReCaptcha: true
            })
            // 发送验证码
            this.props.updateShowedPage(ICheckoutShowedPageNames.SIGN_IN_INPUT_VCODE_PAGE);
            this.props.sendPhoneGetVerificationCode(this.state.vcodeType === 'WHATSAPP');
        } else if (result === 'Expire' || result === 'Error') {
            // 操作失败
            message = 'general_text_operation_failed';
        }
        // 提示
        Notification.notification(i18n.t(message));
    }

    /**
     * 第三方登录需要绑定手机号
     * @param error 错误信息
     * @param type 第三方登录账号类型
     * @param token 第三方的token
     */
    public async loginByThirdParty(type: 'GOOGLE' | 'FACEBOOK' | undefined, token: string) {
        try {
            // let params = { third_party_token: token, third_party_type: type };
            // let result: any = await apiManage.post_customer_init(params);
            // if (_.get(result, 'error') && _.get(result, 'result.statusCode') === 403 && _.get(result, 'result.code') === 1051) {
            //     // 第三方账号，在本应用没有账号
            //     this.props.jumpToPerfectInfo(type, token);
            //     return
            // } else if (_.get(result, 'error')) {
            //     Notification.notification(i18n.t('request_failure_text_operation_failed'));
            //     return
            // }
            // // 用户可能缺少的字段([ 'first_name', 'last_name', 'phone','phone_prefix', 'password', 'is_password_page'])
            // let shortage = _.get(result, 'shortage', []);
            // if (_.includes(shortage, 'phone') || _.includes(shortage, 'phone_prefix')) {
            //     // 在本应用有账号但是没有手机，每次都进入完善信息页
            //     this.props.jumpToPerfectInfo(type, token);
            //     return
            // }
            // // 记录登录token失效日期（90天后）
            // let expiresInDate = moment().add(result.expiresIn, 'seconds').format();
            // 用户id
            // let customerID = result._id;
            // await this.getCustomerInfo({
            //     access_token: result.accessToken,
            //     expiresInDate
            // }, customerID)
            this.props.jumpToPerfectInfo(type, token);
            // this.props.signIn(params);
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * 获取CustomerInfo 并把对应的存在存到缓存中
     * @param token
     */
    public async getCustomerInfo(token: unknown) {
        if (!token) {
            Notification.notification(i18n.t('general_text_invaild_token'));
            cleanAccountCache();
            return
        }
        // 存储token
        LocalStorageManager.setLocalStorage({
            path: LOCAL_STORAGE_KEYS.TOKEN,
            value: token
        });
    }

    /**
     * 获取metada之后
     */
    async getAuthTokenMetadataAfter(customerInfo) {
        // 获取用户头像图片地址
        const getAvatar: any = await apiManage.get_staff_id_avatar(customerInfo._id) || ''
        // 错误捕捉
        if (errorCatching(getAvatar, 'account_page_text_request_avatar_failed')) return;
        _.set(customerInfo, 'avatar', getAvatar)
        const customer: Customer = {
            _id: customerInfo._id,
            nickname: customerInfo.nickname,
            phone: customerInfo.phone,
            phone_prefix: customerInfo.phone_prefix,
            email: customerInfo.email,
            first_name: customerInfo.first_name,
            last_name: customerInfo.last_name,
            avatar: customerInfo.avatar || '',
            // 是否是测试账号
            is_virtual: customerInfo.is_virtual
        }
        //  存储账号信息
        LocalStorageManager.setLocalStorage({
            path: LOCAL_STORAGE_KEYS.ACCOUNT,
            value: customer
        });
        // 登录之后跳转到之前的页面
        if (createHashHistory().length <= 2) {
            const merchantWebsite = _.get(Store.getState(), 'merchantWebsite');
            const currentResStringID = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_RES_STRING_ID);
            if (merchantWebsite) {
                createHashHistory().push(`/restaurantPage?string_id=${currentResStringID}${merchantWebsite
                    ? '&merchant_website=true' : ''}`)
            } else {
                createHashHistory().push('/')
            }
        } else {
            createHashHistory().goBack();
        }
    }

    reRenderText() {
        const { phonePrefix, phone } = this.props;
        const { vcodeType } = this.state;
    
        // 根据 vcodeType 选择合适的翻译键
        const translationKey = vcodeType === 'SMS'
            ? 'delivery_address_page_please_your_verification_code'
            : 'delivery_address_page_please_your_verification_code_with_Whatsapp';
    
        // 获取完整的文本
        const fullText = i18n.t(translationKey, { phone: `${phonePrefix} ${phone}` });
    
        // 提取文本中的数字
        const numberReg = /\d+/g;
        const numbers = fullText.match(numberReg) || [];
    
        // 将数字部分用 span 包裹
        const highlightedNumbers = <span style={{ color: '#5E6899' }}>{numbers.join(' ')}</span>;
    
        // 替换文本中的 'WhatsApp' 部分为高亮样式
        const highlightWhatsApp = (text: string) => {
            const index = text.indexOf('WhatsApp');
            if (index === -1) return text;
            return (
                <>
                    {text.slice(0, index)}
                    <span style={{ color: '#5E6899' }}>WhatsApp</span>
                    {text.slice(index + 8)}
                </>
            );
        };
    
        // 分割文本并应用高亮
        const startIndex = fullText.indexOf(numbers.join(' '));
        const textBeforeNumbers = fullText.slice(0, startIndex);
        const textAfterNumbers = fullText.slice(startIndex + numbers.join(' ').length);
    
        const highlightedTextBefore = vcodeType === 'SMS' ? textBeforeNumbers : highlightWhatsApp(textBeforeNumbers);
        const highlightedTextAfter = vcodeType === 'SMS' ? textAfterNumbers : highlightWhatsApp(textAfterNumbers);
    
        return (
            <>
                {highlightedTextBefore}
                {highlightedNumbers}
                {highlightedTextAfter}
            </>
        );
    }
    /**
     * 切换验证码类型
     * 显示确认弹窗，发送验证码
     */
    switchCodeType() {
        this.setState({
            showVCodeDialog: {
                show: true,
                confirm: async () => {
                    this.setState({
                        showVCodeDialog: {
                            show: false
                        },
                        vcodeType: this.state.vcodeType === 'SMS' ? 'WHATSAPP' : 'SMS'
                    }, () => {
                        // 全局缓存一下对于whatsapp填写验证码的状态
                        LocalStorageManager.setLocalStorage({
                            path: LOCAL_STORAGE_KEYS.VERIFY_CODE_PENDING_SOURCE,
                            value: this.state.vcodeType
                        });
                        this.props.sendPhoneGetVerificationCode(this.state.vcodeType === 'WHATSAPP')
                    })
                }
            }
        })
    }
    // *********************
    // View
    // *********************
    render() {
        const { vcode, bottomPanelVisible, isPassReCaptcha, showGoogleReCaptcha, showReCaptChaDialog, vcodeType } = this.state;
        const { vcodeTimeMS, whatsappVcodeTimeMS, phonePrefix } = this.props;
        return (
            <div className='checkout-sign-in-phone'>
                <div className='headBox'>
                    <span className='actionIcon' onClick={() => this.props.updateShowedPage(ICheckoutShowedPageNames.SIGN_IN_INPUT_PHONE_PAGE)}>
                        <MdChevronLeft />
                    </span>
                    <span className='title'>
                        {i18n.t('reset_password_page_text_insert_vcode')}
                    </span>
                </div>
                <VerificationCode
                    defaultValue={vcode}
                    onChange={(vCode) => this.setState({
                        vcode: vCode
                    })}
                    isCountDown={(vcodeType === 'SMS' ? vcodeTimeMS : whatsappVcodeTimeMS) !== 90}
                    onChangeSecond={() => {
                        this.props.sendPhoneGetVerificationCode(vcodeType === 'WHATSAPP');
                    }}
                    codeSecond={vcodeType === 'SMS' ? vcodeTimeMS : whatsappVcodeTimeMS}
                />
                <div className='code-password-box'>

                    <Typography className='tip-text'>
                        {this.reRenderText()}
                    </Typography>
                    {phonePrefix === '39' && <div className='switch-container' onClick={() => { this.switchCodeType() }}>
                        {i18n.t('login_page_Switch_to_x_verification_code', { x: vcodeType === 'SMS' ? 'WhatsApp' : 'SMS' })}
                    </div>}
                </div>
                {/* <div onClick={() => {
                    this.setState({
                        bottomPanelVisible: true
                    })
                }}>
                    {
                        vcodeTimeMS !== TimeoutAtCodeDelay ?
                            <Typography className='change-button'>
                                {i18n.t('delivery_address_page_resend_verification_code', { number: vcodeTimeMS })}
                            </Typography>
                            :
                            <Typography className='change-button' >
                                {i18n.t('delivery_address_page_not_receive_verification_code')}
                            </Typography>
                    }
                </div> */}

                {/* 选择其他登录方式 */}
                {/* <Button
                    disabled={vcodeTimeMS >= (TimeoutAtCodeDelay - 10) && vcodeTimeMS !== TimeoutAtCodeDelay}
                    fullWidth
                    className={'select-other-login-method-button'}
                    variant='contained'
                    onClick={() => {
                        this.setState({
                            bottomPanelVisible: true
                        })
                    }}
                >
                    {vcodeTimeMS >= (TimeoutAtCodeDelay - 10)
                        && vcodeTimeMS !== TimeoutAtCodeDelay ?
                        `${i18n.t('signin_page_select_other_methods')}(${vcodeTimeMS - (TimeoutAtCodeDelay - 10)})${i18n.t('general_second')}`
                        : i18n.t('signin_page_select_other_methods')
                    }
                </Button> */}
                <ButtonComponent
                    className={!IS_VCODE.test(vcode) ? 'signup-button-not-phone' : 'signup-button-div'}
                    value={i18n.t('signin_page_Confirm_and_log in')}
                    onClick={async () => {
                        console.log('signInBtnAvailable', this.signInBtnAvailable);
                        if (this.signInBtnAvailable === false) return;
                        this.signInBtnAvailable = false;
                        try {
                            if (!IS_VCODE.test(vcode)) return;
                            await this.signIn();
                        } finally {
                            this.signInBtnAvailable = true;
                        }
                    }}
                />
                <div className='bottom-panel'>
                    <div className='line-text'>
                        <span className='line'></span>
                        {i18n.t('signin_page_select_other_methods')}
                        <span className='line'></span>
                    </div>
                    <div className='login-button-div'>
                        <GoogleLoginFixed
                            styleType='icon'
                            styleShape='circle'
                            style={{ height: 'auto' }}
                            callback={(response) => {
                                this.props.jumpToPerfectInfo('GOOGLE', response.credential);
                            }}
                        />
                        {/* <FacebookLogin
                            appId={FACKBOOK_APP_ID}             // facebook应用id
                            autoLoad={false}                         // 自动加载
                            disableMobileRedirect={true}
                            fields='name,email,picture'
                            scope='public_profile'     // 自定义权限
                            callback={async (response) => {
                                this.props.jumpToPerfectInfo('FACEBOOK', response.accessToken)
                            }}        // 调用 responseFacebook 方法
                            render={renderProps => (
                                <div className='facebook' onClick={() => { renderProps.onClick() }}>
                                    <img alt='' className='img' src={facebookPic} />
                                </div>
                            )}
                        /> */}
                    </div>
                </div>
                <Drawer
                    anchor='bottom'
                    onClose={() => {
                        this.setState({
                            bottomPanelVisible: false
                        })
                    }}
                    open={bottomPanelVisible}>
                    <div className='bottomPanel'>
                        <Button
                            fullWidth
                            className={this.props.vcodeTimeMS === TimeoutAtCodeDelay ? 'bottomPanelButton' : 'bottomPanelButton-active'}
                            variant='contained'
                            onClick={() => {
                                if (this.props.vcodeTimeMS !== TimeoutAtCodeDelay) return;
                                if (isPassReCaptcha) {
                                    this.props.sendPhoneGetVerificationCode();
                                    LocalStorageManager.setLocalStorage({
                                        path: LOCAL_STORAGE_KEYS.VERIFY_CODE_PENDING_SOURCE,
                                        value: 'SMS'
                                    });
                                    this.setState({ vcodeType: 'SMS' });
                                } else {
                                    this.setState({ showGoogleReCaptcha: true })
                                }
                            }}
                        >
                            {this.props.vcodeTimeMS !== TimeoutAtCodeDelay ?
                                i18n.t('delivery_address_page_resend_verification_code', { number: vcodeTimeMS })
                                : i18n.t('signup_page_text_send_vcode')}
                        </Button>
                        <Button
                            fullWidth
                            className={this.props.whatsappVcodeTimeMS === TimeoutAtCodeDelay ? 'bottomPanelButton' : 'bottomPanelButton-active'}
                            variant='contained'
                            onClick={async () => {
                                console.log('whatsappVcodeTimeMS', this.props.whatsappVcodeTimeMS);
                                if (this.props.whatsappVcodeTimeMS !== TimeoutAtCodeDelay) return;
                                if (this.whatsappVcodeSendAvailable === false) return;
                                this.whatsappVcodeSendAvailable = false;
                                try {
                                    // 全局缓存一下对于whatsapp填写验证码的状态
                                    LocalStorageManager.setLocalStorage({
                                        path: LOCAL_STORAGE_KEYS.VERIFY_CODE_PENDING_SOURCE,
                                        value: 'WHATSAPP'
                                    });
                                    this.setState({ vcodeType: 'WHATSAPP' });
                                    await this.props.sendPhoneGetVerificationCode(true)
                                } finally {
                                    this.whatsappVcodeSendAvailable = true;
                                }
                            }}>
                            {i18n.t('receive_verification_code_through_whatsapp') + `${whatsappVcodeTimeMS < TimeoutAtCodeDelay ? `(${whatsappVcodeTimeMS})` : ''}`}
                        </Button>
                        <Button
                            fullWidth
                            className='bottomPanelButton'
                            variant='contained'
                            onClick={() => this.props.updateShowedPage(ICheckoutShowedPageNames.SIGN_IN_INPUT_PASSWORD_PAGE)}>
                            {i18n.t('delivery_address_page_use_password_signin')}
                        </Button>
                        {/* <GoogleLogin
                            // tslint:disable-next-line: max-line-length
                            clientId={GOOGLE_CLIENT_ID} // Google应用信息
                            buttonText='Login'                                      // 用于谷歌登陆
                            onSuccess={async (response: any) => {
                                this.loginByThirdParty('GOOGLE', response.tokenId)
                            }}    // 登陆成功 返回token 和用户信息
                            onFailure={(response) => {
                                // console.log('google_error',response);
                            }}    // 初始化失败登陆失败返回调用
                            cookiePolicy={'single_host_origin'}                     // 政策
                            render={renderProps => (
                                <div className='google' onClick={() => { renderProps.onClick() }} >
                                    <img alt='' className='img' src={require('../../static/images/google.png')} />
                                    <span className='text'>{i18n.t('signin_page_text_login_with_google')}</span>
                                </div>
                            )}
                        /> */}
                        <GoogleLoginFixed
                            callback={(response) => {
                                this.loginByThirdParty('GOOGLE', response.credential)
                            }}
                        />
                        {/* <FacebookLogin
                            appId={FACKBOOK_APP_ID}             // facebook应用id
                            autoLoad={false}                         // 自动加载
                            disableMobileRedirect={true}
                            fields='name,email,picture'
                            scope='public_profile'     // 自定义权限
                            callback={async (response: any) => {
                                this.loginByThirdParty('FACEBOOK', response.accessToken)
                            }}        // 调用 responseFacebook 方法
                            render={renderProps => (
                                <div className='facebook' onClick={() => { renderProps.onClick() }}>
                                    <img alt='' className='img' src={facebookPic} />
                                    <span className='text'>{i18n.t('signin_page_text_login_with_Facebook')}</span>
                                </div>
                            )}
                        /> */}
                        <Button
                            fullWidth
                            className='bottomPanelButton cancelPanelButton'
                            variant='contained'
                            onClick={() => this.setState({
                                bottomPanelVisible: false
                            })}>
                            {i18n.t('general_text_cancel')}
                        </Button>
                    </div>
                </Drawer>
                {/* 谷歌人机校验获取token*/}
                {
                    <FeatGoogleReCaptcha
                        showGoogleReCaptcha={showGoogleReCaptcha}
                        onVerify={async (token) => {
                            this.setState({
                                showGoogleReCaptcha: false
                            })
                            this.checkReCaptchaToken(token)
                        }} />
                }
                {/* 谷歌人机选择图像校验*/}
                {
                    showReCaptChaDialog &&
                    <ReCaptChaDialog
                        handleClose={() => { this.setState({ showReCaptChaDialog: false }) }}
                        handelReCaptchaCallBack={(result) => { this.handelReCaptchaCallBack(result) }}
                    />
                }
                <VCodeDialog
                    show={this.state.showVCodeDialog.show}
                    confirm={() => {
                        this.state.showVCodeDialog.confirm?.()
                    }}
                    vcodeType={vcodeType === 'SMS' ? 'WhatsApp' : 'SMS'}
                    onClose={() => {
                        this.setState({ showVCodeDialog: { show: false } })
                    }} />
            </div >
        )
    }
}

export default SignInCodePage


// *********************
// Props & State
// *********************

interface State extends ICheckoutSignInInputVcodeState {
    showGoogleReCaptcha: boolean;
    showReCaptChaDialog: boolean;
    isPassReCaptcha: boolean;
    vcodeType: 'WHATSAPP' | 'SMS';
    showVCodeDialog: {
        show: boolean;
        confirm?: () => void;
    }
}

interface Props extends ICheckoutSignInInputVcodeProps {
    sendPhoneGetVerificationCode: (toWhatsapp?: boolean) => void;
    // 第三方登录成功去补全信息
    jumpToPerfectInfo: (type: 'GOOGLE' | 'FACEBOOK' | undefined, token: string) => void;
    signIn: (pamar: signIn_param) => void;
    // whatsapp 按钮验证码倒计时时间（秒）
    whatsappVcodeTimeMS: number
}


