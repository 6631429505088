import React from 'react';
import { CheckCircle, Cancel, Visibility, VisibilityOff } from '@material-ui/icons';
import '../static/css/sign-in.scss';
import { IS_PASSWORD } from '../constants/base.constant';
import i18n from '../i18n';

export default function PasswordInput(props: {
    className: any;
    defaultValue: string;
    onChange?: (val: string) => void;
    isSame?: boolean;
    placeholder?: string;
    showTip?: boolean;
}) {
    const _showTip = props.showTip ?? true;
    const [isPhone, setIsPhone] = React.useState(IS_PASSWORD.test(props.defaultValue));
    const [isdisplay, setIsdisplay] = React.useState(false);
    const [isType, setIsType] = React.useState(false);
    return (
        <>
            <div
                className={props.className}
            >
                <input type='password' style={{ display: 'none', width: 0, height: 0 }}></input>
                <input
                    placeholder={props.placeholder}
                    className='signin-password-input'
                    autoComplete='new-password'
                    value={props.defaultValue}
                    type={isType ? 'text' : 'password'}
                    onChange={(val: any) => {
                        if (typeof props.onChange === 'function') props.onChange(val.target.value);
                        setIsPhone(IS_PASSWORD.test(val.target.value))
                        // setIsdisplay(val.target.value.length > 1)
                    }}
                />
                {
                    props.defaultValue.length > 0 ? (
                        isPhone && props.isSame ?
                            <CheckCircle
                                style={{
                                    color: '#2dcc56', width: '15%'
                                }}
                                className='signin-label'
                            /> :
                            <Cancel style={{ color: 'red', width: '15%' }} className='signin-label' />) :
                        <Cancel style={{ color: '#FFF', fontSize: 18, width: '15%' }} />
                }
                {
                    isdisplay ? <Visibility className='signin-visibility'
                        onClick={() => {
                            setIsType(!isType);
                            setIsdisplay(!isdisplay);
                        }}
                    /> :
                        <VisibilityOff className='signin-visibility'
                            onClick={() => {
                                setIsType(!isType);
                                setIsdisplay(!isdisplay);
                            }}
                        />
                }

            </div>
            <div>
                <p className='password-tip-text' >
                    {props.defaultValue.length && _showTip ?
                        isPhone ?
                            !props.isSame ? i18n.t('signup_page_text_invalid_password_confirm') : ''
                            : i18n.t('signup_page_text_invalid_password_format')
                        : ''}
                </p>
            </div>
        </>
    )
}