import React from 'react';
import { ICheckoutShowedPageNames, ICheckoutSignInInputPhone, ICheckoutSignInInputPhoneProps, ICheckoutSignInInputPhoneState } from '../../interfaces';
import { Typography, FormControlLabel, Checkbox, Drawer, Button, } from '@material-ui/core';
import { PhoneInput } from '../../component/payment/payment.componet';
import ButtonComponent from '../button.component';
import './scss/checkout-sign-in-phone.scss';
import { MdChevronLeft, MdClose } from 'react-icons/md';
import { IS_PHONE } from '../../constants/base.constant';

import ReCaptChaDialog from '../recaptcha-dialog/recaptcha-dialog';
import { GOOGLE_ROBOT_SCORE } from '../../gconfig';
import apiManage from '../../request';
import Notification from '../../component/snackbar/snackbar';
import i18n from '../../i18n';
import _ from 'lodash';
import { PrivacyApgreementDialog } from '../privacy-apgreement/privacy-apgreement-dialog.componet';
import { FeatGoogleReCaptcha } from '../../page/sign-in/sign-in.page';
import { GoogleLoginFixed } from '../google-login/google-login';
import { Close } from '@material-ui/icons';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import { LocalStorageManager } from '../../constants/tool-local-storage';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
// import facebookPic from '../../static/images/facebook.png';
import { ReactComponent as SwitchIcon } from '../../static/images/switch.svg';
import PasswordInput from '../password-input.component';
import { signIn_param } from './checkout-sign-in-password-page';

class SignInPhonePage extends React.Component<Props, State> implements ICheckoutSignInInputPhone {

    constructor(props) {
        super(props);
        this.reRenderText = this.reRenderText.bind(this);
        this.googleLoginRef = React.createRef();
    }

    public state: State = {
        // 谷歌人机校验获取token
        showGoogleReCaptcha: false,
        // 谷歌人机校验图像弹窗
        showReCaptChaDialog: false,
        // 是否通过人机校验
        isPassReCaptcha: false,
        isShowPrivacyApgreementDialog: false,
        isAgree: false,
        bottomPanelVisible: false,
        bottomPanelConfirm: () => { },
        loginType: 'code',
        password: ''
    }

    signInBtnAvailable = true;
    private googleLoginRef: React.RefObject<HTMLDivElement> | null = null;
    /**
     * 通过reCaptchaToken解析分值
     */
    public async checkReCaptchaToken(reCaptchaToken) {
        try {
            if (reCaptchaToken) {
                const result = await apiManage.googleReCaptchaSiteverify({ token: reCaptchaToken })
                if (!_.get(result, 'error')) {
                    if (result.score <= GOOGLE_ROBOT_SCORE) {
                        this.setState({
                            showReCaptChaDialog: true
                        })
                    } else {
                        // 评分高，已验证不是机器人
                        this.setState({
                            isPassReCaptcha: true
                        })
                        await this.checkLoginType();
                    }
                }
            }
        } catch (error) { }
    }

    /**
     * 处理ReCaptchaV2回调
     */
    public handelReCaptchaCallBack(result: 'Success' | 'Expire' | 'Error') {
        // 操作成功
        let message = 'general_text_successful_operation';
        if (result === 'Success') {
            // 通过成功
            this.setState({
                isPassReCaptcha: true
            })
            this.checkLoginType();
        } else if (result === 'Expire' || result === 'Error') {
            // 操作失败
            message = 'general_text_operation_failed';
        }
        // 提示
        Notification.notification(i18n.t(message));
    }

    /**
     * 发送验证码并且跳转验证码页面
     * 根据isShowForgetPassword判断跳转普通验证码页面还是忘记密码页面
     */
    public async sendPhoneGetVerificationCode() {
        const isWhatsapp = this.props.phonePrefix !== '39';
        // 全局缓存一下对于whatsapp填写验证码的状态
        LocalStorageManager.setLocalStorage({
            path: LOCAL_STORAGE_KEYS.VERIFY_CODE_PENDING_SOURCE,
            value: isWhatsapp ? 'WHATSAPP' : 'SMS'
        });
        const { isShowForgetPassword } = this.props;
        // 跳转验证码页面
        if (!isShowForgetPassword) {
            this.props.updateShowedPage(ICheckoutShowedPageNames.SIGN_IN_INPUT_VCODE_PAGE)
        }

        // 发送验证码
        await this.props.sendPhoneGetVerificationCode(isWhatsapp);
    }

    reRenderText() {
        const { phonePrefix } = this.props;
        let text = i18n.t('delivery_address_page_signin_prompt_message');
        let length = 3;
        let startIndex = text.indexOf('SMS');
        // 如果不是意大利国家，则使用Whatsapp
        if (phonePrefix !== '39') {
            text = i18n.t('delivery_address_page_signin_prompt_message_with_Whatsapp');
            length = 8;
            startIndex = text.indexOf('Whatsapp');
        }
        const endIndex = startIndex + length;

        return (
            <>
                {text.slice(0, startIndex)}
                <span style={{ color: '#5E6899' }}>{text.slice(startIndex, endIndex)}</span>
                {text.slice(endIndex)}
            </>
        )
    }

    async checkLoginType() {
        const { phonePrefix, phone } = this.props;
        const { loginType, password } = this.state;
        console.log(loginType, password);
        // 区分密码 或者 发验证码
        if (loginType === 'code') {
            // 发送验证码
            await this.sendPhoneGetVerificationCode();
        } else {
            // 密码登录
            await this.props.signInPhoneWithPassword({
                phone: phone,
                phone_prefix: phonePrefix,
                password,
                type: 'password'
            })
        }
    }

    async login() {
        const { phonePrefix, phone } = this.props;
        const { isAgree, } = this.state;
        if (IS_PHONE(phonePrefix, phone) && !isAgree) {
            // 打开确认隐私协议弹窗
            this.setState({
                bottomPanelVisible: true,
                bottomPanelConfirm: () => this.login()
            })
            return
        }
        if (!(IS_PHONE(phonePrefix, phone) && isAgree)) { return; }
        if (this.signInBtnAvailable === false) { return; }
        this.signInBtnAvailable = false;
        try {
            console.log('signInBtnAvailable', this.state.isPassReCaptcha);
            if (this.state.isPassReCaptcha) {
                this.checkLoginType();
            } else {
                this.props.checkoutClearInterval();
                this.setState({ showGoogleReCaptcha: true });
            }
        } finally {
            this.signInBtnAvailable = true;
        }
    }
    // *********************
    // View
    // *********************
    render() {
        const { showGoogleReCaptcha, showReCaptChaDialog, isShowPrivacyApgreementDialog, isAgree, bottomPanelVisible, loginType, password } = this.state;
        const { phonePrefix, phone, isShowBack, isShowForgetPassword } = this.props;

        return (
            <div className='checkout-sign-in-phone'>
                <div className='headBox'>
                    <span className='actionIcon' onClick={() => this.props.closeCheckoutComponent()}>
                        {
                            isShowBack ?
                                <MdChevronLeft />
                                :
                                <MdClose />
                        }
                    </span>
                    <span className='title'>
                        {isShowForgetPassword ? i18n.t('signin_page_text_forget_password') : i18n.t('signin_page_text_log_in_Register')}
                    </span>
                </div >
                <PhoneInput
                    className={'signUpPhoneInput'}
                    hideLabel={true}
                    selectItem={phonePrefix}
                    defaultInput={phone}
                    handleChange={(val) => {
                        this.props.updateState({
                            phone: val?.trim()
                        });
                    }}
                    handleSelect={(val) => {
                        this.props.updateState({
                            phonePrefix: val
                        });
                    }}
                    type='autoComplete'
                />
                <div className='code-password-box'>
                    {loginType === 'code' || isShowForgetPassword ? <Typography className='tip-text'>
                        {this.reRenderText()}
                    </Typography> :
                        <>
                            <PasswordInput
                                className='sign-up-password'
                                defaultValue={password}
                                isSame={true}
                                onChange={(val: string) => {
                                    this.setState({ password: val });
                                }}
                                placeholder={i18n.t('delivery_address_page_please_enter_your_password')}
                                showTip={false}
                            />
                            {!isShowForgetPassword && this.props.clickForgetPassword && <Typography className='change-button' onClick={() => {
                                this.setState({ loginType: 'code' });
                                this.props.clickForgetPassword?.();
                            }}>
                                {i18n.t('signin_page_text_forget_password')}
                            </Typography>}
                        </>}
                </div>
                <ButtonComponent
                    className={!(IS_PHONE(phonePrefix, phone) && isAgree && (loginType === 'password' ? password : true)) ? 'signup-button-not-phone' : 'signup-button-div'}
                    value={i18n.t('signup_page_button_confirm')}
                    onClick={async () => {
                        if ((IS_PHONE(phonePrefix, phone) && (loginType === 'password' ? password : true))) {
                            await this.login();
                        }
                    }}
                />

                <div className='agreePolicy'  >
                    <FormControlLabel
                        className='agreePolicyCheckBox'
                        control={
                            <Checkbox
                                checked={this.state.isAgree}
                                onChange={() => {
                                    this.setState({
                                        isAgree: !this.state.isAgree
                                    })
                                }}
                                value='checkBoxValue'
                                style={{ color: isAgree ? '#15BE6C' : '#9F9BBB' }}
                            />
                        }
                        label={<>
                            <span className='agreeText'>
                                {i18n.t('privacy_agreement_text_by_logging_in_registering_you_agree_to')}
                            </span>
                            <span className='clickPolicyText'
                                onClick={(e) => { e.stopPropagation(); this.setState({ isShowPrivacyApgreementDialog: true }) }}
                            >
                                {i18n.t('privacy_agreement_text')}
                            </span>
                        </>}
                    />
                </div>
                {/* 谷歌人机校验获取token*/}
                {
                    <FeatGoogleReCaptcha
                        showGoogleReCaptcha={showGoogleReCaptcha}
                        onVerify={async (token) => {
                            this.setState({
                                showGoogleReCaptcha: false
                            }, () => {
                                this.checkReCaptchaToken(token);
                            })
                        }} />
                }
                {/* 谷歌人机选择图像校验*/}
                {
                    showReCaptChaDialog &&
                    <ReCaptChaDialog
                        handleClose={() => { this.setState({ showReCaptChaDialog: false }) }}
                        handelReCaptchaCallBack={(result) => { this.handelReCaptchaCallBack(result) }}
                    />
                }
                {
                    isShowPrivacyApgreementDialog
                        ? <PrivacyApgreementDialog
                            // onConfirm={() => { this.isAgree() }}
                            handleClose={() => { this.setState({ isShowPrivacyApgreementDialog: false }) }}
                        />
                        : null
                }
                <div className='bottom-panel'>
                    <div className='line-text'>
                        <span className='line'></span>
                        {i18n.t('signin_page_select_other_methods')}
                        <span className='line'></span>
                    </div>
                    <div className={`login-button-div`} onClick={() => {
                        if (!isAgree) {
                            this.setState({
                                bottomPanelVisible: true,
                                bottomPanelConfirm: () => {
                                    const dom: any = this.googleLoginRef?.current?.querySelector('div[role="button"]');
                                    dom?.click();
                                }
                            });
                        }
                    }}>
                        <div ref={this.googleLoginRef} style={{ pointerEvents: isAgree ? 'auto' : 'none' }} >
                            <GoogleLoginFixed
                                styleType='icon'
                                styleShape='circle'
                                style={{ height: 'auto' }}
                                callback={(response) => {
                                    this.props.jumpToPerfectInfo('GOOGLE', response.credential);
                                }}
                            />
                        </div>
                        {/* <FacebookLogin
                            appId={FACKBOOK_APP_ID}             // facebook应用id
                            autoLoad={false}                         // 自动加载
                            disableMobileRedirect={true}
                            fields='name,email,picture'
                            scope='public_profile'     // 自定义权限
                            callback={async (response) => {
                                this.props.jumpToPerfectInfo('FACEBOOK', response.accessToken)
                            }}        // 调用 responseFacebook 方法
                            render={renderProps => (
                                <div className='facebook' onClick={() => { renderProps.onClick() }}>
                                    <img alt='' className='img' src={facebookPic} />
                                </div>
                            )}
                        /> */}
                    </div>
                    {!isShowForgetPassword && <div className='switch-box' onClick={() => { this.setState({ loginType: loginType === 'password' ? 'code' : 'password' }) }}>
                        <SwitchIcon className='switch-icon' />
                        <span className='switch-text'>
                            {loginType === 'password' ? i18n.t('signin_page_text_Verification_code_login') : i18n.t('signin_page_text_Account_password_login')}
                        </span>
                    </div>}
                </div>
                <Drawer
                    anchor='bottom'
                    onClose={() => {
                        this.setState({
                            bottomPanelVisible: false
                        })
                    }}
                    open={bottomPanelVisible}>
                    <div className='agree-bottom-panel-content'>
                        <div className='bottom-panel-title'>
                            {i18n.t('signin_page_text_Please_read_and_agree_to_the_following_terms')}
                            <Button className='close-btn' onClick={() => { this.setState({ bottomPanelVisible: false }) }}>
                                <Close />
                            </Button>
                        </div>
                        <div className='bottom-panel-content-text' onClick={() => { this.setState({ isShowPrivacyApgreementDialog: true }) }}>
                            <span>{`《${i18n.t('signin_page_text_Privacy agreement')}》`}</span>
                            <span>{`《${i18n.t('signin_page_text_Data_usage_agreement')}》`}</span>
                        </div>
                        <div className='bottom-panel-btn'>
                            <ButtonComponent
                                className='agree-button-div'
                                value={i18n.t('signin_page_text_Agree_and_continue')}
                                onClick={async () => {
                                    this.setState({
                                        bottomPanelVisible: false,

                                        isAgree: true
                                    }, () => {
                                        this.state.bottomPanelConfirm?.()
                                    })
                                }}
                            />
                        </div>
                    </div>
                </Drawer>
            </div >
        )
    }
}

export default SignInPhonePage


// *********************
// Props & State
// *********************

interface State extends ICheckoutSignInInputPhoneState {
    showGoogleReCaptcha: boolean;
    showReCaptChaDialog: boolean;
    isPassReCaptcha: boolean;
    isShowPrivacyApgreementDialog: boolean;
    isAgree: boolean;
    bottomPanelVisible: boolean;
    bottomPanelConfirm: () => void;
    loginType: 'code' | 'password';
    password: string;
}

interface Props extends ICheckoutSignInInputPhoneProps {
    sendPhoneGetVerificationCode: (toWhatsapp?: boolean) => void;
    // 显示返回还是关闭按钮 默认显示关闭按钮
    isShowBack?: boolean;
    // 点击下一步是否跳转忘记密码页面 默认为否
    isShowForgetPassword?: boolean;
    checkoutClearInterval: () => void;
    // 点击忘记密码
    clickForgetPassword?: () => void;
    // 登录接口
    signInPhoneWithPassword: (param: signIn_param) => Promise<void>;
}


