import * as React from 'react';
import TopBar from '../top-bar/top-bar.component';
import { Button, Typography } from '@material-ui/core';
import { Check, Clear } from '@material-ui/icons';
import VerificationCode from './verification-code.component'
import PhoneInput from './phone.component';
import apiManage from '../../request';
import { MessengerModule } from '../../messenger/module';
import { withSnackbar } from 'notistack';
import { withTranslation, WithTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { Customer } from '../../models';
import { addCookie, getCookieValue, editCookie } from '../../tool/cookie-code'
import { IS_PHONE, IS_EMAIL, IS_VCODE } from '../../constants/base.constant';
import { errorCatching } from '../../tool/error-catching';
import { showApiMessage } from '../../messenger/messageManager';
import _ from 'lodash';
import { getBrowserPhoneCode } from '../../constants/tool-get-browser-language';
import { LocalStorageManager } from '../../constants/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../constants/local-storage.constant';
import Notification from '../snackbar/snackbar';
import { TimeoutAtCodeDelay } from '../../page/sign-in/sign-in.page';
import { BindPhoneDialog } from '../bind-phone/bind-phone-dialog.component';
import { VCodeDialog } from '../v-code-dialog/v-code-dialog';
let timeCode: NodeJS.Timeout;

class ChangeEmailOrTel extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            tit: '',
            phonePrefix: '39',
            insertEmail: '',
            insertPhone: '',
            insertCode: '',
            codeSecond: 90,
            isCountDown: false,
            customerId: '',
            sendPhone: '',
            sendEmail: '',
            isShowSendText: false,
            isOpenDialog: false,
            // 验证码类型
            vcodeType: 'SMS',
            showVCodeDialog: {
                show: false
            }
        }
        this.messengerModule = new MessengerModule(this.props.enqueueSnackbar);
    }
    // 消息框
    private messengerModule: MessengerModule;

    // ***************
    //  生命周期
    // ***************

    UNSAFE_componentWillMount() {

        const accountInfo = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT);
        // 判断 用户信息是否存在
        if (accountInfo) {
            this.setState({
                customerId: accountInfo._id
            })
        }
        // 判断倒计时是否结束
        clearInterval(timeCode);
        const countdown = getCookieValue(this.getSecondsName())
            ? getCookieValue(this.getSecondsName()) : '0'; // 获取cookie值

        this.setState({
            sendPhone: getCookieValue('phoneSent') ? getCookieValue('phoneSent') : '',
            sendEmail: getCookieValue('emailSent') ? getCookieValue('emailSent') : ''
        })
        if (countdown !== undefined && parseInt(countdown, 10) > 0) {
            this.changeSecond();
        }
        // 初始化code计时
        this.setState({
            isCountDown: parseInt(countdown, 10) ? true : false,
            codeSecond: parseInt(countdown, 10)
        })
    }

    componentDidMount() {
        // 根据浏览器当前语言，自动识别（若识别出错，则默认IT）
        this.setState({
            phonePrefix: getBrowserPhoneCode(),
            vcodeType: getBrowserPhoneCode() === '39' ? 'SMS' : 'WHATSAPP'
        })
    }
    componentWillUnmount() {
        this.setState = () => { return }
    }

    // ***************
    // 方法区
    // ***************

    public insertPhone = '';
    public insertEmail = '';

    /**
     * 获取验证码倒计时的key值名称
     */
    getSecondsName = () => {
        if (this.props.isShowPhone) return 'secondsRemainedPhone'
        else return 'secondsRemainedEmail'
    }

    // 开启倒计时
    changeSecond = () => {
        let countdown = TimeoutAtCodeDelay;
        countdown = parseInt(getCookieValue(this.getSecondsName()), 10);
        timeCode = setInterval(() => {
            if (countdown <= 0) {
                clearInterval(timeCode);
                this.setState({
                    isCountDown: false
                })
            } else {
                countdown--;
                this.setState({
                    isCountDown: true,
                    codeSecond: countdown
                })
            }
            editCookie(this.getSecondsName(), countdown, countdown + 1);
        }, 1000);
    }

    // 点击确认
    public async confirm(force?: boolean) {
        const { insertEmail, insertPhone, insertCode, phonePrefix } = this.state;
        const { isShowEmail, isShowPhone } = this.props;
        try {
            let text = ''
            if (isShowEmail && !IS_EMAIL.test(insertEmail)) {
                text = 'delivery_address_page_text_please_enter_correct_email'
            } else if (isShowPhone && insertPhone && !IS_PHONE(phonePrefix, insertPhone)) {
                text = 'delivery_address_page_text_please_enter_correct_phone'
            } else if (!insertCode) {
                text = 'reset_password_page_text_insert_vcode'
            } else if (!IS_VCODE.test(insertCode)) {
                text = 'delivery_address_page_text_please_enter_verification_code'
            }
            if (text) {
                Notification.notification(i18n.t(`${text}`));
                return;
            }
            await this.setCustomerInfo(force);
        } catch (error) {

        }
    }

    public async setCustomerInfo(force?: boolean) {
        const { customerId, insertEmail, insertPhone, insertCode, phonePrefix, vcodeType } = this.state;
        const { isShowEmail, changeStatus } = this.props;
        try {
            if (isShowEmail) {
                const RES_BIND = await apiManage.post_bind_email(customerId, insertEmail, insertCode, 'customer');
                if (_.get(RES_BIND, 'error')) {
                    const statusCode = _.get(RES_BIND, 'result.statusCode');
                    const code = _.get(RES_BIND, 'result.code');
                    let text = '';
                    if (statusCode === 404) {
                        text = 'reset_password_page_text_vcode_mismatched';
                    } else if (statusCode === 422 && code === 11000) {
                        text = 'account_page_text_the_current_Email_account_is_linked_to_another_account';
                    }
                    else if (statusCode === 403 && code === 'CODE_1003') {
                        text = 'reset_password_page_text_vcode_mismatched';
                    }
                    else if (statusCode === 403 && code === 'CODE_1004') {
                        text = 'general_text_verification_code_expired';
                    }
                    else {
                        text = 'account_page_text_binding_failed';
                    }
                    Notification.notification(i18n.t(`${text}`));
                    return
                }
            } else {
                const RES_BIND: any = await apiManage.post_bind_phone(
                    customerId, insertPhone, insertCode, 'customer', phonePrefix,
                    force ? '1' : undefined, vcodeType
                );
                if (_.get(RES_BIND, 'error')) {
                    let text = '';
                    const statusCode = _.get(RES_BIND, 'result.statusCode');
                    const code = _.get(RES_BIND, 'result.code');
                    if (code === 1062) {
                        // 绑定手机号已存在
                        this.setState({ isOpenDialog: true });
                        return;
                    }
                    if (statusCode === 404) {
                        text = 'reset_password_page_text_vcode_mismatched';
                    } else if (statusCode === 403 && code === 'CODE_1003') {
                        text = 'reset_password_page_text_vcode_mismatched';
                    } else if (statusCode === 403 && code === 'CODE_1004') {
                        text = 'reset_password_page_text_vcode_mismatched';
                    } else if (statusCode === 422 && code === 11000) {
                        text = 'signup_page_text_account_the_current_phone_number_has_been_registered';
                    } else {
                        text = 'account_page_text_binding_failed'
                    }
                    Notification.notification(i18n.t(`${text}`));
                    return
                }
            }
            // 合并账号，会退出登录
            const customerInfo: any = await apiManage.get_customer_info(customerId);
            // 错误捕捉
            if (errorCatching(customerInfo, 'account_page_text_request_avatar_failed')) return;
            let account: Customer = LocalStorageManager.getLocalStorage(LOCAL_STORAGE_KEYS.ACCOUNT) || {};

            if (customerInfo) {
                account = {
                    ...account,
                    _id: customerInfo._id,
                    nickname: customerInfo.nickname,
                    phone: customerInfo.phone,
                    phone_prefix: customerInfo.phone_prefix,
                    email: customerInfo.email,
                    first_name: customerInfo.first_name,
                    last_name: customerInfo.last_name,
                    my_recommendation_code: customerInfo.my_recommendation_code
                }
                LocalStorageManager.setLocalStorage({
                    path: LOCAL_STORAGE_KEYS.ACCOUNT,
                    value: account
                });
            }
            Notification.notification(i18n.t('delivery_address_page_text_success'));
            if (isShowEmail) {
                changeStatus({
                    isShowEmail: false,
                    isShowPhone: false,
                    insertEmail,
                    customerInfo: customerInfo
                })
            } else {
                changeStatus({
                    isShowEmail: false,
                    isShowPhone: false,
                    insertPhone,
                    insertPhonePrefix: phonePrefix,
                    customerInfo: customerInfo
                })
            }
        } catch (error) {
        }
    }

    updateState = (value: any) => {
        this.setState(value)
    }
    async sendCode() {
        const { insertEmail, insertPhone, phonePrefix, vcodeType } = this.state;
        const { isShowEmail, t } = this.props;
        const insertType = isShowEmail ?
            IS_EMAIL.test(insertEmail) : IS_PHONE(phonePrefix, insertPhone)
        if (insertType) {
            // 发送验证码类型
            let codeType = '';
            if (isShowEmail) {
                // 修改邮箱
                codeType = 'BIND_EMAIL'
            } else if (!isShowEmail) {
                // 修改密码
                codeType = 'BIND_PHONE'
                if (vcodeType === 'WHATSAPP') {
                    codeType = 'INIT_BY_WHATS';
                }
            }
            const postData = {
                account_type: 'CUSTOMER',
                type: codeType,
                // 手机或邮箱
                email: isShowEmail ? insertEmail : undefined,
                phone_prefix: !isShowEmail ? phonePrefix : undefined,
                phone: !isShowEmail ? insertPhone : undefined,
                // 邮箱主题
                subject: isShowEmail ? `${i18n.t('account_page_text_collect_email')} ${t('signup_page_text_vcode')}` : undefined
            }
            //  请求验证码
            const RESULT_VCODE = await apiManage.post_admin_code(postData)
            // 错误捕捉
            if (RESULT_VCODE.error) {
                showApiMessage(RESULT_VCODE, 'vCodeEmail')
                return
            }

            this.setState({
                sendPhone: insertPhone,
                sendEmail: insertEmail,
                isShowSendText: true
            })
            // 添加cookie记录,有效时间90s
            addCookie(this.getSecondsName(), 90, 90);
            addCookie('phoneSent', insertPhone, 90);
            addCookie('emailSent', insertEmail, 90);
            this.changeSecond();
        } else {
            Notification.notification(i18n.t(`${isShowEmail ?
                t('delivery_address_page_text_please_enter_correct_email') :
                t('delivery_address_page_text_please_enter_correct_phone')}`));
        }
    }
    /**
     * 切换验证码类型
     * 显示确认弹窗，发送验证码
     */
    switchCodeType() {
        this.setState({
            showVCodeDialog: {
                show: true,
                confirm: async () => {
                    this.setState({
                        showVCodeDialog: {
                            show: false
                        },
                        vcodeType: this.state.vcodeType === 'SMS' ? 'WHATSAPP' : 'SMS'
                    }, async () => {
                        // 发送验证码
                        await this.sendCode();
                    })
                }
            }
        })
    }

    reRenderSendedText() {
        const { vcodeType, insertPhone, phonePrefix } = this.state;

        // 根据 vcodeType 选择合适的翻译键
        const translationKey = vcodeType === 'SMS'
            ? 'delivery_address_page_please_your_verification_code'
            : 'delivery_address_page_please_your_verification_code_with_Whatsapp';

        // 获取完整的文本
        const fullText = i18n.t(translationKey, { phone: `${phonePrefix} ${insertPhone}` });

        // 提取文本中的数字
        const numberReg = /\d+/g;
        const numbers = fullText.match(numberReg) || [];

        // 将数字部分用 span 包裹
        const highlightedNumbers = <span style={{ color: '#5E6899' }}>{numbers.join(' ')}</span>;

        // 替换文本中的 'WhatsApp' 部分为高亮样式
        const highlightWhatsApp = (text: string) => {
            const index = text.indexOf('WhatsApp');
            if (index === -1) return text;
            return (
                <>
                    {text.slice(0, index)}
                    <span style={{ color: '#5E6899' }}>WhatsApp</span>
                    {text.slice(index + 8)}
                </>
            );
        };

        // 分割文本并应用高亮
        const startIndex = fullText.indexOf(numbers.join(' '));
        const textBeforeNumbers = fullText.slice(0, startIndex);
        const textAfterNumbers = fullText.slice(startIndex + numbers.join(' ').length);

        const highlightedTextBefore = vcodeType === 'SMS' ? textBeforeNumbers : highlightWhatsApp(textBeforeNumbers);
        const highlightedTextAfter = vcodeType === 'SMS' ? textAfterNumbers : highlightWhatsApp(textAfterNumbers);

        return (
            <>
                {highlightedTextBefore}
                {highlightedNumbers}
                {highlightedTextAfter}
            </>
        );
    }

    reRenderText() {
        const { phonePrefix, vcodeType } = this.state;
        let text = i18n.t('delivery_address_page_signin_prompt_message');
        let length = 3;
        let startIndex = text.indexOf('SMS');
        // 如果不是意大利国家，则使用Whatsapp
        if (!(phonePrefix === '39' && vcodeType === 'SMS')) {
            text = i18n.t('delivery_address_page_signin_prompt_message_with_Whatsapp');
            length = 8;
            startIndex = text.indexOf('Whatsapp');
        }
        const endIndex = startIndex + length;

        return (
            <>
                {text.slice(0, startIndex)}
                <span style={{ color: '#5E6899' }}>{text.slice(startIndex, endIndex)}</span>
                {text.slice(endIndex)}
            </>
        )
    }
    // ***************
    // VIEW
    // ***************

    public render() {
        const { insertEmail, insertCode, codeSecond, isCountDown, insertPhone, phonePrefix, isOpenDialog, isShowSendText, vcodeType } = this.state;
        const { isShowPhone, isShowEmail } = this.props;
        return (
            <div className='change-email-main change-phone-main  overall-center'>
                {/* Top bar */}
                <TopBar
                    backgroundstyle={'backgroundTransparent'}
                    isHideMenu={true}
                    title={isShowEmail ? `${i18n.t('account_page_text_collect_your_email')}` : `${i18n.t('delivery_address_page_text_change_phone_number')}`}
                    isGoBack={() => {
                        this.props.changeStatus({
                            isShowEmail: false,
                            isShowPhone: false
                        })
                    }} />
                <div className='change-email-content change-phone-content'>
                    <div className='main-body'>
                        <div>
                            <div className='account-tit'>{isShowEmail ? `${i18n.t('account_page_text_new_email')}` :
                                `${i18n.t('delivery_address_page_text_new_phone_number')}`}</div>
                            {
                                isShowEmail && <div>
                                    <EmailInput
                                        className='phone-main input-main'
                                        defaultValue={insertEmail}
                                        onChange={(val: string) => {
                                            this.setState({
                                                insertEmail: val
                                            });
                                        }} />
                                </div>
                            }
                            {
                                isShowPhone && <div className='phone-input'>
                                    <PhoneInput
                                        className='phone-main input-main'
                                        defaultValue={insertPhone}
                                        phonePrefix={phonePrefix}
                                        onChangePhonePrefix={(val: string) => {
                                            this.setState({
                                                phonePrefix: val,
                                                vcodeType: val === '39' ? 'SMS' : 'WHATSAPP'
                                            });
                                        }}
                                        onChange={(val: string) => {
                                            this.setState({
                                                insertPhone: val
                                            });
                                        }}
                                    />
                                </div>
                            }
                            <div>
                                {/* 输入验证码 */}
                                <VerificationCode
                                    codeSecond={codeSecond}
                                    className='code-main code-input-main'
                                    defaultValue={insertCode}
                                    isCountDown={isCountDown}
                                    onChangeSecond={async () => {
                                        this.sendCode()
                                    }}
                                    onChange={(val: string) => {
                                        this.setState({
                                            insertCode: val
                                        });
                                    }} />
                            </div>
                            {isShowPhone && <>
                                <Typography className='tip-text'>
                                    {isShowSendText ? this.reRenderSendedText() : this.reRenderText()}
                                </Typography>
                                {phonePrefix === '39' && <div className='switch-container' onClick={() => { this.switchCodeType() }}>
                                    {i18n.t('login_page_Switch_to_x_verification_code', { x: vcodeType === 'SMS' ? 'WhatsApp' : 'SMS' })}
                                </div>}
                            </>}
                        </div>
                        <Button
                            // disableTouchRipple={true}
                            disableElevation={true}
                            variant='contained'
                            className='confirm boxShadow-btn' onClick={() => {
                                this.confirm()
                            }}>{i18n.t('delivery_address_page_text_address_confirmed')}</Button>
                    </div>
                </div>
                {isOpenDialog &&
                    <BindPhoneDialog
                        onConfirm={() => { this.confirm(true); }}
                        handleClose={() => { this.setState({ isOpenDialog: false }) }} />}
                <VCodeDialog
                    show={this.state.showVCodeDialog.show}
                    confirm={() => {
                        this.state.showVCodeDialog.confirm?.()
                    }}
                    vcodeType={vcodeType === 'SMS' ? 'WhatsApp' : 'SMS'}
                    onClose={() => {
                        this.setState({ showVCodeDialog: { show: false } })
                    }} />
            </div>
        )
    }

}

export default withTranslation()<Props>(withSnackbar(ChangeEmailOrTel as any) as any);

function EmailInput(props: {
    className: any;
    defaultValue: string;
    onChange?: (val: string) => void;
}) {
    const [isEmail, setIsEmail] = React.useState(true);
    return (
        <div
            className={props.className}
        >
            <input
                className='email-insert-in'
                value={props.defaultValue}
                onChange={(val: any) => {
                    if (typeof props.onChange === 'function') props.onChange(val.target.value);
                    setIsEmail(IS_EMAIL.test(val.target.value))
                }}
            />

            {
                props.defaultValue.length > 0 ? (
                    isEmail ?
                        <Check style={{
                            position: 'absolute', right: 10, color: '#fff',
                            width: 20, height: 20, padding: 1, backgroundColor: '#2dcc56', borderRadius: 50
                        }} className='signin-label' /> :
                        <Clear style={{
                            position: 'absolute', right: 10, color: '#fff',
                            width: 20, height: 20, padding: 1, backgroundColor: 'red', borderRadius: 50
                        }} className='signin-label' />) :
                    <Check style={{
                        position: 'absolute', right: -100, color: '#FFF',
                        fontSize: 18, width: 20, height: 20, backgroundColor: '#2dcc56', borderRadius: 50
                    }} />
            }
        </div>
    )
}

// **********
// TYPEF
// **********

type Props = {
    changeStatus: (val: any) => void
    // 手机号
    phone: string;
    // 显示手机输入
    isShowPhone: boolean;
    // 显示邮箱输入
    isShowEmail: boolean;
    // 消息栏
    enqueueSnackbar?: any;
} & WithTranslation;

type State = {
    // 标题
    tit: string;
    phonePrefix: string;
    // 邮箱
    insertEmail: string;
    // 验证码
    insertCode: string;
    // 验证码倒计时
    codeSecond: number
    // 是否在倒计时
    isCountDown: boolean
    insertPhone: string
    // 用户id
    customerId: string
    // 设置发送的手机号
    sendPhone: string
    // 设置发送的邮箱
    sendEmail: string
    // 显示已发送的文本
    isShowSendText: boolean
    // 绑定手机号提示
    isOpenDialog: boolean
    vcodeType: 'WHATSAPP' | 'SMS';
    showVCodeDialog: {
        show: boolean;
        confirm?: () => void;
    }
}
